<template style="background-color: var(--background-color);">
  <Loading v-if="loadingActive"/>
  <LiveOrderCart v-if="showCart" @close-cart="closeCartModal" :currentShop="currentShop"  :city="this.$route.params.city" :user="user" :liveId="liveSessionData.id"/>
<!--  <LiveOrderCart v-if="showCart" @close-cart="closeCartModal" :currentShop="currentShop"  :city="city" :user="user" :selectedItem="selectedItem"/>-->
  <PhoneAuthModal v-show="loginModal" v-on:close-modal="closeModal"/>
  <InfoDialog v-if="modalActive" :modal-message="modalMessage" v-on:close-modal="closeInfoModal"/>
  <div class="container-fluid pt-5 mt-1" style="background-color: var(--background-color); color: var(--text-color) !important;">

    <div class="row">
      <div class="vwrap p-2 live-wrapper" style="position:sticky;height: 45px; top: 50px;" ><div class="vmove">
        <div class="vitem">{{currentShop.name}},  </div>
        <div class="vitem">{{liveSessionData.name}} Live Sales</div>
        <div class="vitem">.........</div>
      </div>
      </div>
<!--      Category Box-->
<!--      <div class="col-12 col-lg-2 m-0 p-0 shadow-sm d-none">
        <div class="category-box" v-if="categories.length>0">
          <div class="d-flex d-none d-lg-block my-3">
            <span class="fs-3 text-dark fw-bold">
              <span class="badge bg-danger">Live</span> Sales
            </span>
          </div>
          <ul class="text-start d-block list-unstyled">
            <li :class="product_type==='all'?'list_active':''" class="d-inline-block rounded-pill mx-1 shadow-sm" @click="showProduct('all')">
              <img src="../assets/flash/1.png" class="rounded-circle" style="width:25px;height:25px;object-fit:cover;" alt="">
              <span class="mx-2">All</span>
            </li>
            <li v-for="(category, index) in categories" :key="index" :class="product_type===category.name?'list_active':''" class="d-inline-block rounded-pill mx-1 shadow-sm" @click="showProduct(category.name)">
              <img :src="category.url"  v-if="category.url!=='default'" class="rounded" style="width:50px;height:50px;object-fit:cover;">
              <img src="../assets/flash/1.png" class="rounded-circle" style="width:25px;height:25px;object-fit:cover;" alt="">
              <span class="mx-2">{{category.name}}</span>
            </li>
          </ul>
        </div>
      </div>-->

      <div class="col-12 " v-if="liveSessionData">
        <button @click="$router.back();" class="btn btn--white" style="color: var(--text-color);"><i class="fas fa-chevron-left"></i></button>
        <div class="row align-items-start">
          <div class="col-12 col-lg-6 mt-2 mx-auto" v-if="liveSessionData.fb_url">
            <iframe :src=filterLiveUrl class="fb-video" data-mobile style="border:none;overflow:hidden;width: 100%;" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
          </div>
          <div class="col-12 col-lg-6 mx-auto ">
            <div  class="content-box">
              <div class="d-flex text-start ps-2 align-items-center">
                <div>
                  <Vue3Lottie :animationData="live" :height="60" :width="60"/>
                </div>
                <span class="fs-5">{{$t('message.live_sale_product')}}</span>
              </div>
              <div class="px-0 scrolling-wrapper">
                <div class="shop-card" v-for="product in filterProducts" :key="product.id">
                  <div class="d-flex rounded" >
                    <div class="">
                      <div id="carouselExampleControls" v-if="product.images.length>0" class="carousel slide" data-bs-ride="carousel">
                        <div class="carousel-inner">
                          <div class="carousel-item" :class="index===0?'active':''" v-for="(img, index) in product.images" :key="index">
                            <img :src="img" class="carousel-img" alt="">
                          </div>
                        </div>
                      </div>
                      <img src="../assets/flash/1.png" class="carousel-img" v-else alt="">
                    </div>
                    <div class="ms-1 p-1 d-flex flex-column fw-bold" style="cursor:pointer;">
                      <div class="d-flex justify-content-between align-items-start" style="height: 50px;">
                        <span class="w-75 text-wrap text-truncate" >{{product.itemName}}</span>
                        <span class="badge w-25 bg-danger text-white rounded" v-if="product.discount>0"><i class="fas fa-tags"></i>{{product.discount}}%</span>
                      </div>
                      <div>
                        <span class="" v-if="product.liveSaleCount>0">{{product.liveSaleCount}} {{$t('message.item_available')}}</span>
                        <span class="text-danger" v-else>Out Of Stock</span>
                      </div>
                      <div v-if="product.discount>0" class="d-flex align-items-center" style="height: 45px;">
                        <span class="text-decoration-line-through" style="font-size: 11px;">{{product.sellPrice}}</span>
                        <span class="text-danger fw-bold ms-2">{{product.sellPrice-(product.sellPrice*(product.discount/100))}} Ks</span>
                      </div>
                      <div v-else style="height: 45px;">
                        <span>{{product.sellPrice}} Ks</span>
                      </div>

                    </div>
                  </div>
                  <button type="button" :disabled="product.liveSaleCount<=0" @click="addToCart(product)" class="btn mt-1 btn-sm w-100" style="background-color: var(--btnLight);">
                    {{$t('message.buy_now')}}
                  </button>

                </div>

              </div>

            </div>


          </div>
        </div>
      </div>

<!--
      <div class="col-12 col-lg-9" v-else>
        <div class="row py-5">
          <h1 class="text-primary">Live Sales Finished! </h1>
          <h2>Thank You all my customers</h2>
        </div>
      </div>
-->

    </div>
  </div>
</template>

<script>
// import { runTransaction } from "firebase/firestore";
import PhoneAuthModal from "@/components/PhoneAuthModal";
import InfoDialog from "@/components/InfoDialog";
import db from "../config/FirebaseInit"
import "firebase/compat/firestore"
import Loading from "@/components/Loading";
import LiveOrderCart from "@/components/LiveOrderCart";
const live = require('../assets/lotties/live-dot.json');
//import AddToCart from "@/components/AddToCart";
export default {
  components:{LiveOrderCart, Loading, PhoneAuthModal, InfoDialog},
  //props:['city','shop_id','product_type'],
  data(){
    return{
      live,
      showCart:false,
      loadingActive:false,
      currentShop:'',
      categories:[],
      products:[],
      selectedItem:'',
      orderMessage:'',
      totalPrice:0,
      cartOrders:[],
      selectedCategory:'all',
      liveSessionData:'',
      loginModal:false,
      modalActive:false,
      modalMessage:'',
      disableBtn:false,
    }
  },
  async mounted(){
    await db.collection(this.$route.params.city).where('name','==',this.$route.params.shop_name.split("-").join(" ")).onSnapshot(snapshot => {
      snapshot.forEach(doc => {
        this.currentShop = doc.data();
      })
      console.log(this.currentShop)
      db.collection(this.$route.params.city).doc(this.currentShop.id).collection('category').get().then(snapshot => {
        snapshot.forEach(doc=>{
          if(doc.data().name!=='All'){
            this.categories.push(doc.data());

          }
        })
        db.collection(this.$route.params.city)
            .doc(this.currentShop.id)
            .collection('liveSession')
            .onSnapshot((snapshot) => {
              snapshot.forEach((doc)=>{
                if(doc.data().status ==='start'){
                  this.liveSessionData = doc.data();
                  db.collection(this.$route.params.city).doc(this.currentShop.id).collection('liveSession').doc(this.liveSessionData.id).collection('liveProducts').onSnapshot(snapshot => {
                    this.products = [];
                    snapshot.forEach(doc => {
                      this.products.push(doc.data())
                    })
                  })
                /*  if(this.product_type==='all') {

                  }else {
                    db.collection(this.$route.params.city).doc(this.currentShop.id).collection('liveSession').doc(this.liveSessionData.id).collection('liveProducts').where('type','==',this.product_type).onSnapshot(snapshot => {
                      this.products = [];
                      snapshot.forEach(doc=>{
                        this.products.push(doc.data());
                      })
                    })
                  }*/
                }
              });
            });
      });

    });


  },
  computed: {
    filterLiveUrl(){
      return 'https://www.facebook.com/plugins/video.php?height=470&href='+this.liveSessionData.fb_url+'&show_text=true&width=560&t=0';
    },
    user(){
      return this.$store.getters.getCurrentUser;
    },
    filterProducts(){
      if(this.selectedCategory !== 'all'){
        return this.products.filter(product=>{
          return product.type === this.selectedCategory
        })
      }else {
        return this.products;
      }

    }
  },
  methods:{
   closeCartModal(){
     this.showCart = !this.showCart;
   },

    addToCart(product){

      if(this.user){
        this.selectedItem = {
          'count':1,
          'productId':product.id,
          'liveSaleCount':product.liveSaleCount,
          'itemName':product.itemName,
          'buyPrice':product.buyPrice,
          'discount':product.discount,
          'itemCode':product.itemCode,
          'sellPrice':'',
          'totalPrice':'',
          'image':'',
        }
        if(product.images.length>0){
          this.selectedItem.image = product.images[0];
        }else {
          this.selectedItem.image = 'https://firebasestorage.googleapis.com/v0/b/flash-mall-ed921.appspot.com/o/default%2F1.png?alt=media&token=b935b93f-8b63-431b-93bd-ef52bcb2f3e0'
        }
        // console.log(this.selectedItem)
        if(product.discount!=='0'){
          this.selectedItem.sellPrice = product.sellPrice-(product.sellPrice*(product.discount/100));
          this.selectedItem.totalPrice = this.selectedItem.sellPrice;
        }else {
          this.selectedItem.sellPrice = product.sellPrice;
          this.selectedItem.totalPrice = this.selectedItem.sellPrice;

        }
        this.$store.commit("SET_SELECTED_LIVE_ITEM", this.selectedItem);
        this.showCart = true;

      }
      else {
        this.loginModal =true;
      }
    },

    closeModal(){
      this.loginModal = !this.loginModal;
    },
    closeInfoModal(){
      this.modalActive = !this.modalActive  ;

    }
  }
}
</script>
<style>

</style>
<style scoped>
.content-box{
  position: fixed;
  overflow-y: scroll;
  height: 80vh;
  width: inherit;
  border-radius: var(--border-radius);
  background-color: var(--light);
}
.comment-box{
  position: fixed;
  width:300px;
  overflow-y: scroll;
  height: 80%;
//background-color: var(--secondary);
}
.card{
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--light) !important;
  border-radius: var(--border-radius) !important;
  width: 95%;
}
/*.carousel-inner{
  margin: 0;
  padding-top: 5px;
  height: 180px;
}*/

.carousel-img{
  margin: 0;
  border-radius: var(--border-radius);
  object-fit: cover;
  width: 100%;
  height: 80px;
}
li{
  cursor: pointer;
  background-color: white;
  align-items: center;
  margin-bottom: 10px;
  padding: 5px;
  width: 95%;
  height: 35px;
  border-radius: var(--border-radius);
  transition: 0.3s ;
}
li:hover, li:focus{
  background-color: #e33737;
  color: white;
}
.list_active{
  background-color: #e33737;
  color: white;
}
.category-box{
  z-index: 5000;
  top:110px;
  background-color: white;
  padding-top: 20px;
  padding-left: 10px;
  height: 100%;
  position: fixed;
  width: 200px;
  overflow-x: hidden;
  overflow-y: scroll;
  padding-bottom: 100px;
}

.add-to-card-bar{
  position: fixed;
  padding: 10px;
  width: 100%;
  background-color: white;
  z-index: 5000;
}

.btn{
  border: none;
}

.btn-add{
  display: flex;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  border: none;
}

/*animated gradient*/
.live-wrapper {
  background: linear-gradient(45deg, #f82929, #e94545, #ff4242, #f82929,#f82929, #f82929);
  background-size: 600% 100%;
  animation: gradient 16s linear infinite;
  animation-direction: alternate;
}
@keyframes gradient {
  0% {background-position: 0%}
  100% {background-position: 100%}
}

.vwrap, .vitem {
  height: 30px;
  line-height: 30px;
}

/* (B) FIXED WRAPPER */
.vwrap {
  overflow: hidden; /* HIDE SCROLL BAR */
}
/* (C) TICKER ITEMS */
.vitem { text-align: center; color: white}

/* (D) ANIMATION - MOVE ITEMS FROM TOP TO BOTTOM */
/* CHANGE KEYFRAMES IF YOU ADD/REMOVE ITEMS */
.vmove { position: relative; }
@keyframes tickerv {
  0% { bottom: 0; } /* FIRST ITEM */
  20%{bottom:20px;}
  30% { bottom: 30px; } /* SECOND ITEM */
  60% { bottom: 60px; } /* THIRD ITEM */
  80% {bottom:80px;}
  90% { bottom: 90px; } /* FORTH ITEM */
  100% { bottom: 0; } /* BACK TO FIRST */
}
.vmove {
  animation-name: tickerv;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  animation-timing-function: cubic-bezier(0.5, 0, .5, 0);
}
.vmove:hover { animation-play-state: paused; }

@media only screen and (max-width: 1200px){
  .category-box {
    width: 180px;
  }
}
.fb-video{
  height: 600px;
}
.shop-card{
  background-color: var(--background-color);
  border-radius: var(--border-radius) !important;
  margin: 5px;
  width: 280px;
  padding: 5px;
  display: inline-block;
}
.comment-box{

}
@media only screen and (max-width: 900px){
  .content-box{
    bottom: 5px;
    margin-left: 0px;
    width: 95%;
    height: 30%;
  }
  .comment-box{
    width: 98%;
    height: 25%;
  }
  .fb-video{
    height: 400px;
  }
  .scrolling-wrapper {
    width: inherit;
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
  }


  .category-box{
    padding: 0;
    z-index: 5000;
    position: fixed;
    box-sizing: border-box;
    width: 100%;
    top:100px;
    margin-top: 0px;
    margin-left: 0px;
    height: inherit;
    align-items: center;
  }
  .content-box{
    margin-top: 55px;
  }
  li{
    margin: 5px;
    width: inherit;
    display: inline;
  }
  ul{
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
}
@media only screen and (max-width: 1100px){

}
</style>